<template>
  <b-card-code title="Modifier une voiture">
    <b-card-text>
      <span>Veuillez modifier les coordonnées de voiture </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form v-if="car">
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Matricule"
              label-for="matricule"
            >
              <validation-provider
                #default="{ errors }"
                name="matricule"
                rules="required"
              >
                <b-form-input
                  v-model="car.serial_number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Matricule"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Marque"
              label-for="marque"
            >
              <validation-provider
                #default="{ errors }"
                name="marque"
                rules="required"
              >
                <b-form-input
                  v-model="car.brand"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Marque "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Couleur"
              label-for="Couleur"
            >
              <validation-provider
                #default="{ errors }"
                name="Couleur"
                rules="required"
              >
                <b-form-input
                  v-model="car.color"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Couleur"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :class="{ 'disabled-cursor': isLoading || !isCarValid }"
              :disabled="isLoading || !isCarValid"
              @click.prevent="editCar"
            >
              Modifier
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      required,
      car: {},
      isLoading: false,
    }
  },
  computed: {
    isCarValid() {
      return (
        this.car.brand !== ''
        && this.car.serial_number !== ''
        && this.car.color !== ''
      )
    },
  },
  created() {
    this.getCar()
  },
  methods: {
    async getCar() {
      try {
        this.isLoading = true
        const { data } = await axios.get(`/api/cars/detail/${this.$route.params.id}`)
        this.car = data
        this.isLoading = false
      } catch (error) {
        this.showToast('danger', 'top-center', 'error')
      }
    },
    async editCar() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.isLoading = true
            await axios.put(`/api/cars/update/${this.$route.params.id}/`, this.car)
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Modification effectuée avec succès')
            }, 1000)
            this.isLoading = false
            this.$router.push('/cars/')
          } catch (error) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', error.toString())
            }, 1000)
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champ obligatoire ! vérifier votre donnée !')
          }, 1000)
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
